import { Grid, GridProps, styled } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

interface IBannerLayoutProps extends GridProps {
    banner: React.ReactNode;
    disableElevation?: boolean;
}

const Root = styled(Grid)({
    height: "100%",
});

const ElevatedContainer = styled(Grid)(({ theme }) => ({
    zIndex: theme.zIndex.drawer,
}));

const ContentContainer = styled(Grid)(({ theme }) => ({
    flexGrow: 1,
    flexShrink: 1,
    height: 0, // work around bug in chrome 69/safari with unscollable page
    overflow: "auto",
}));

const BannerLayout = ({ banner, children, disableElevation, ...otherProps }: React.PropsWithChildren<IBannerLayoutProps>) => {
    const BannerContainer = disableElevation ? Grid : ElevatedContainer;
    return (
        <Root container direction="column" wrap="nowrap" {...otherProps}>
            <BannerContainer item>{banner}</BannerContainer>
            <ContentContainer item>{children}</ContentContainer>
        </Root>
    );
};

BannerLayout.propTypes = {
    banner: PropTypes.element.isRequired,
    children: PropTypes.element.isRequired,
};

export default BannerLayout;
